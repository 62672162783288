<template>
  <div
    class="phone-field form-control max-w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid focus-within:ring-blue-500 focus-within:border-blue-500 rounded-xl transition ease-in-out m-0 focus-within:text-gray-700 focus-within:bg-white focus-within:border-brand focus-within:outline-none -mr-2 sm:-mr-0 w-full border-box h-10"
    :class="{
      'border-box-stroke': !error,
      'border-red-600': error,
    }"
  >
    <VueTelInput
      v-model="getValue"
      v-bind="config"
      :disabled="disabled || formAlreadySubmitted"
    />
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  props: {
    fieldData: {
      type: Object,
      require: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formAlreadySubmitted: {
      type: Boolean,
    }
  },
  components: {
    VueTelInput,
  },
  computed: {
    config() {
      return  {
        inputOptions: {
          showDialCode: true,
          placeholder: this.getPlaceHolder,
          styleClasses: ['phone-field__input px-3 py-1.5 h-fit'],
        },
        // autoDefaultCountry: false,
        // mode: remote
        disabled: this.disabled,
        validCharactersOnly: true,
        styleClasses: ['phone-field__wrapper'],
        dropdownOptions: {
          disabled: this.disabled,
          showDialCodeInList: true,
          showFlags: true,
        },
        defaultCountry:"GB",
      }
    },
    getPlaceHolder() {
      return this.fieldData.placeholder || "";
    },
    getValue: {
      get() {
        return this.fieldData.value || "";
      },
      set(newValue) {
        this.$emit("input", newValue.replace(/\s+/g,""));
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/assets/mixins/mixins.scss';

.phone-field {
  &:focus-within {
    box-shadow: 0 0 0 1px rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }
  &__wrapper {
    border: none;
    ::v-deep {
      .vti__dropdown {
        @apply border-r border-solid border-gray-300;
      }
      .vti__dropdown-list {
        @include scrollBar;
        padding: 0;
        width: 390px !important;
        .vti__dropdown-item {
          list-style: none;
        }
      }
    }
  }
}
::v-deep {
  .phone-field {
    &__input {
      @apply rounded-r-xl;
      border: none;
    }
  }
}


@media screen and (max-width:768px){
  .phone-field{
    border-radius:0px;
    margin:auto;
    width:100%;
  }
}
</style>
